@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

html {
  scroll-behavior: smooth;
  color: #525252;
}

.pastel-brown-bg {
  background-color: #e7e0da;
}
.pastel-darker-bg {
  background-color: #dfd6ce;
}
.logo-color {
  color: #3e0909;
}
.logo-color-bg {
  background-color: #3e0909;
}
.brown-bg {
  background-color: #c0ae9e;
  color: #fff;
}
.logo-bg {
  background-color: #fff;
}
.font-DosisRegular {
  font-family: "DosisRegular";
}
.font-DosisBold {
  font-family: "DosisBold";
}
.font-DosisMedium {
  font-family: "DosisMedium";
}
.font-InterExtraBold {
  font-family: "InterExtraBold";
}
.font-InterBold {
  font-family: "InterBold";
}
.font-InterRegular {
  font-family: "InterRegular";
}
.font-InterMedium {
  font-family: "InterMedium";
}
@layer base {
  @import "font.css";
}
body {
  font-family: "font-DosisRegular", "font-InterRegular";
  scroll-behavior: smooth;
}
.pre-header {
  background: #fff;
  height: 40px;
}
.main-header {
  background-color: #f4f4f4;
}
.title-style-2 hr {
  width: 23px;
  height: 2px;
  background: #e4b248;
  margin: 0 auto;
  margin-top: 13px;
}
/* carousel */
.image-container,
.facility-image {
  height: 400px;
  position: relative;
  overflow: hidden;
}
.image-container img,
.facility-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* @media (min-width: 1024px) {
  .image-container,
  .facility-image {
    width: 50%;
    height: 400px;
  }
} */
@media (max-width: 640px) {
  .slider-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.slick-dots li button:before {
  font-size: 16px !important;
}
.slick-dots li.slick-active button:before {
  color: #3e0909 !important;
}

.image-container {
  position: relative;
  /* other styles for image container */
}

.caption {
  position: absolute;
  top: 50%;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  width: auto;
  padding: 10px 30px 10px 40px;
  box-sizing: border-box;
  opacity: 0.7;
  height: 60px;
  transform: skew(-20deg);
}
.room-caption {
  position: relative;
}
.room-caption p {
  background-color: #eaeaea;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  left: 0;
}
.contactus {
  position: relative;
}
.contactus .icon {
  position: absolute;
  top: -20px;
  left: 50%;
}
.flip-card {
  perspective: 1000px;
  height: 100%;
  width: 100%;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flip-card-back {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotateY(180deg);
}
